import { useStaticQuery, graphql } from "gatsby";
import { mapContentPage } from "@src/helpers/craft";

const query = graphql`
  {
    craft {
      entry(section: "termsAndConditions") {
        ... on Craft_termsAndConditions_termsAndConditions_Entry {
          title
          body
          description
        }
      }
    }
  }
`;

export const useCraft = () => {
  const { craft } = useStaticQuery(query);
  return mapContentPage(craft);
};
